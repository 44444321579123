import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import loadable from '@loadable/component'

const BetbyLauncher = loadable(() => import('./betby-launcher'))

export function SportsLauncher() {
  const { provider } = ReactRouterDom.useParams()

  switch (provider) {
    case 'betby': {
      return (
        <BetbyLauncher />
      )
    }

    default: {
      return <pre>Unsupported provider: {provider}</pre>
    }
  }
}

export default SportsLauncher
